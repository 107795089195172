import React from "react"

const Form = ({ name = "Contact" }) => {
  return (
    <form name={name} data-netlify="true" netlify-honeypot="bot-field">
      <input type="hidden" name="form-name" value={name} />
      <p class="hidden">
        <label>
          Don’t fill this out if you’re human: <input name="bot-field" />
        </label>
      </p>
      <div className="mb-4 relative">
        <label htmlFor="name">
          <input
            required
            placeholder="Name"
            id="name"
            name="Name"
            className="input"
            type="text"
          />
        </label>
      </div>
      <div className="mb-4">
        <label htmlFor="email">
          <input
            required
            id="email"
            name="Email"
            placeholder="Email"
            className="input"
            type="text"
          />
        </label>
      </div>
      <div className="mb-4">
        <label htmlFor="location">
          <input
            name="Location"
            id="location"
            placeholder="Location"
            className="input"
            type="text"
          />
        </label>
      </div>
      <div className="mb-4">
        <label htmlFor="details">
          <textarea
            id="details"
            name="Details"
            placeholder="Details (Optional)"
            className="input"
            rows="6"
          />
        </label>
      </div>
      <button
        type="submit"
        className="w-full text-white bg-sjh-primary hover:bg-sjh-dark  duration-200 p-2 uppercase rounded-sjh text-xl font-bold transform hover:scale-105 transition-all"
      >
        Send
      </button>
    </form>
  )
}

export default Form
